export const images = [
  {
    file: "Mount Vernon-4.JPG",
    slide: "true",
    date: "5/13/2021",
    type: "interiors",
    col: "3",
    project: "Truckee Minimal",
  },

  {
    file: "Book-3.JPG",
    slide: "true",
    date: "3/13/2021",
    client: "RCP Construction",
    type: "interiors",
    col: "1",
  },
  {
    file: "Book-5.JPG",
    slide: "false",
    type: "interiors",
    col: "1",
  },
  {
    file: "Book-6.JPG",
    slide: "true",
    project: "North Star",
    type: "interiors",
    col: "1",
  },
  {
    file: "Book-7.JPG",
    slide: "false",
    project: "North Star",
    type: "interiors",
    col: "1",
  },
  {
    file: "Book-8.JPG",
    slide: "true",
    project: "North Star",
    type: "interiors",
    col: "1",
  },
  {
    file: "Book-9.JPG",
    slide: "false",
    project: "North Star",
    type: "interiors",
    col: "1",
  },
  {
    file: "Book-18.JPG",
    slide: "false",
    date: "5/14/2021",
    type: "interiors",
    col: "1",
  },
  {
    file: "Book-19.JPG",
    slide: "false",
    date: "5/14/2021",
    type: "interiors",
    col: "1",
  },
  {
    file: "Book-21.JPG",
    slide: "true",
    type: "interiors",
    col: "1",
  },
  {
    file: "Book-22.JPG",
    slide: "true",
    type: "interiors",
    col: "1",
  },
  {
    file: "Book-24.JPG",
    slide: "true",
    type: "interiors",
    col: "2",
  },
  {
    file: "Book-34.JPG",
    slide: "false",
    date: "6/27/2021",
    client: "ODS Architecture",
    project: "Modern Farmhouse",
    type: "interiors",
    col: "2",
  },
  {
    file: "website unused-101.JPG",
    slide: "false",
    date: "6/27/2021",
    project: "Modern Farmhouse",
    type: "interiors",
    col: "2",
    client: "ODS Architecture",
  },
  {
    file: "interiors2.JPG",
    slide: "true",
    date: "6/27/2021",
    client: "ODS Architecture",
    project: "Modern Farmhouse",
    type: "interiors",
    col: "3",
  },
  {
    file: "interiors3.JPG",
    slide: "true",
    project: "Tahoe Condo",
    type: "interiors",
    col: "2",
  },
  {
    file: "interiors4.JPG",
    slide: "true",
    date: "6/27/2021",
    client: "ODS Architecture",
    project: "Modern Farmhouse",
    type: "interiors",
    col: "2",
  },
  {
    file: "interiors5.JPG",
    slide: "true",
    date: "5/14/2021",
    type: "interiors",
    col: "2",
  },
  {
    file: "interiors6.JPG",
    slide: "false",
    type: "interiors",
    col: "2",
  },
  {
    file: "interiors8.JPG",
    slide: "false",
    type: "interiors",
    col: "2",
  },
  {
    file: "interiors7.JPG",
    slide: "true",
    date: "5/13/2022",
    project: "Truckee Minimal",
    type: "interiors",
    col: "22",
  },
  {
    file: "Book-2.JPG",
    slide: "false",
    date: "3/13/2022",
    type: "interiors",
    col: "3",
    client: "RCP Construction",
  },
  {
    file: "Mount Vernon-6.JPG",
    slide: "false",
    date: "5/13/2022",
    project: "Truckee Minimal",
    type: "interiors",
    col: "2",
  },
  {
    file: "website unused-6.JPG",
    slide: "false",
    type: "interiors",
    col: "3",
  },
  {
    file: "website unused-7.JPG",
    slide: "false",
    date: "5/14/2021",
    type: "interiors",
    col: "3",
  },
  {
    file: "website unused-9.JPG",
    slide: "false",
    date: "5/14/2021",
    type: "interiors",
    col: "3",
  },
  {
    file: "website unused-10.JPG",
    slide: "false",
    type: "interiors",
    col: "3",
  },
  {
    file: "website unused-11.JPG",
    slide: "false",
    type: "interiors",
    col: "3",
  },
  {
    file: "website unused-12.JPG",
    slide: "false",
    type: "interiors",
    col: "3",
  },
  {
    file: "website unused-100.JPG",
    slide: "false",
    date: "6/27/2021",
    client: "ODS Architecture",
    project: "Modern Farmhouse",
    type: "interiors",
    col: "3",
  },
  {
    file: "interiors1.JPG",
    slide: "true",
    date: "5/13/2021",
    project: "Truckee Modern",
    type: "interiors",
    col: "2",
  },
  {
    file: "Book-1.JPG",
    slide: "false",
    date: "3/13/2021",
    client: "RCP Construction",
    type: "interiors",
    col: "3",
  },
  {
    file: "Book-10.JPG",
    slide: "false",
    project: "North Star",
    type: "exteriors",
    col: "3",
  },
  {
    file: "Book-11.JPG",
    slide: "true",
    project: "North Star",
    type: "exteriors",
    col: "1",
  },
  {
    file: "Book-12.JPG",
    slide: "true",
    type: "exteriors",
    col: "2",
  },
  {
    file: "Book-13.JPG",
    slide: "true",
    type: "exteriors",
    col: "1",
  },
  {
    file: "Book-14.JPG",
    slide: "true",
    type: "exteriors",
    project: "Chico State",
    col: "1",
  },
  {
    file: "Book-15.JPG",
    slide: "true",
    type: "exteriors",
    project: "Chico State",
    col: "1",
  },
  {
    file: "Book-26.JPG",
    slide: "false",
    type: "exteriors",
    project: "Napa Valley Performing Arts Center",
    col: "2",
  },
  {
    file: "Book-27.JPG",
    slide: "true",
    type: "exteriors",
    project: "Napa Valley Performing Arts Center",
    col: "2",
  },
  {
    file: "Book-29.JPG",
    slide: "false",
    type: "exteriors",
    col: "2",
  },
  {
    file: "Book-30.JPG",
    slide: "false",
    type: "exteriors",
    col: "3",
  },
  {
    file: "Book-31.JPG",
    slide: "true",
    date: "6/27/2021",
    client: "ODS Architecture",
    project: "Modern Farmhouse",
    type: "exteriors",
    col: "2",
  },
  {
    file: "Book-36.JPG",
    slide: "true",
    date: "6/27/2021",
    client: "ODS Architecture",
    project: "Modern Farmhouse",
    type: "exteriors",
    col: "2",
  },
  {
    file: "Book-37.JPG",
    slide: "true",
    date: "6/27/2021",
    client: "ODS Architecture",
    project: "Modern Farmhouse",
    type: "exteriors",
    col: "1",
  },
  {
    file: "Mount Vernon-1.JPG",
    slide: "true",
    date: "3/26/2022",
    project: "Hill Top Oaks Winery",
    type: "exteriors",
    col: "1",
  },
  {
    file: "Mount Vernon-2.JPG",
    slide: "false",
    date: "3/26/2022",
    project: "Hill Top Oaks Winery",
    type: "exteriors",
    col: "1",
  },
  {
    file: "the rest-2.JPG",
    slide: "false",
    type: "exteriors",
    col: "1",
  },
  {
    file: "the rest-3.JPG",
    slide: "false",
    type: "exteriors",
    col: "1",
  },
  {
    file: "the rest-4.JPG",
    slide: "false",
    type: "exteriors",
    col: "1",
  },
  {
    file: "the rest-5.JPG",
    slide: "true",
    type: "exteriors",
    col: "2",
  },
  {
    file: "the rest-6.JPG",
    slide: "false",
    type: "exteriors",
    col: "2",
  },
  {
    file: "the rest-7.JPG",
    slide: "false",
    type: "exteriors",
    project: "Chico State",
    col: "2",
  },
  {
    file: "the rest-8.JPG",
    slide: "false",
    type: "exteriors",
    project: "Chico State",
    col: "3",
  },
  {
    file: "the rest-9.JPG",
    slide: "false",
    type: "exteriors",
    project: "Chico State",
    col: "3",
  },
  {
    file: "the rest-10.JPG",
    slide: "true",
    type: "exteriors",
    project: "Truckee Modern",
    date: "5/13/2021",
    col: "2",
  },
  {
    file: "the rest-11.JPG",
    slide: "false",
    type: "exteriors",
    project: "Truckee Modern",
    date: "5/13/2021",
    col: "3",
  },
  {
    file: "the rest-12.JPG",
    slide: "false",
    type: "exteriors",
    col: "2",
  },
  {
    file: "the rest-13.JPG",
    slide: "true",
    type: "exteriors",
    col: "3",
  },
  {
    file: "the rest-14.JPG",
    slide: "false",
    type: "exteriors",
    col: "3",
  },
  {
    file: "the rest-15.JPG",
    slide: "true",
    type: "exteriors",
    col: "3",
  },
  {
    file: "the rest-16.JPG",
    slide: "false",
    date: "6/27/2021",
    client: "ODS Architecture",
    project: "Modern Farmhouse",
    type: "exteriors",
    col: "3",
  },
  {
    file: "the rest-17.JPG",
    slide: "false",
    date: "6/27/2021",
    client: "ODS Architecture",
    project: "Modern Farmhouse",
    type: "exteriors",
    col: "3",
  },
];
